<script setup lang="ts">
const links = [{
  label: 'Features',
  to: '#features',
  icon: 'i-heroicons-cube-transparent',
  exactHash: true
}, {
  label: 'Pricing',
  to: '#pricing',
  icon: 'i-heroicons-credit-card',
  exactHash: true
}, {
  label: 'Testimonials',
  to: '#testimonials',
  icon: 'i-heroicons-academic-cap',
  exactHash: true
}, {
  label: 'FAQ',
  to: '#faq',
  icon: 'i-heroicons-question-mark-circle',
  exactHash: true
}]
const { signIn, signOut, status } = useAuth()
const loggedIn = computed(() => status.value === 'authenticated')

</script>

<template>
  <UHeader :links="links">
    <template #logo>
        Agentsmith
    </template>

    <template v-if="loggedIn" #right>
      <UButton label="Sign out" @click="signOut({ callbackUrl: '/' })"  color="gray" variant="ghost" class="hidden lg:flex" />
      <UButton label="Go to dashboard" @click="navigateTo('/agents')" color="primary" class="hidden lg:flex" />
    </template>
    <template v-else #right>
      <UButton label="Sign in" @click="signIn(provider='auth0', options={ callbackUrl: '/agents' })" color="gray" variant="ghost" class="hidden lg:flex" />
      <UButton label="Get started" color="black" class="hidden lg:flex" />
    </template>

    <template #panel>
      <UAsideLinks :links="links" />

      <UDivider class="my-6" />

      <UContainer  v-if="loggedIn">
        <UButton label="Go to dashboard" @click="navigateTo('/agents')" size="xl" color="primary" block class="mb-3" />
        <UButton label="Sign out" @click="signOut({ callbackUrl: '/' })" size="xl" color="gray" block class="mb-3" />
      </UContainer>
      <UContainer v-else>
        <UButton label="Sign in"  @click="signIn(provider='auth0', options={ callbackUrl: '/agents' })" size="xl" color="gray" block class="mb-3" />
        <UButton label="Get started" @click="signIn(provider='auth0', options={ callbackUrl: '/agents' })" size="xl" block />
      </UContainer>
      
    </template>
  </UHeader>
</template>